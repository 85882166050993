import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/page-layout.vue'
import mbLayout from '@/layout/mb-page-layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: layout,
    children:[
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/homePage.vue'),
        meta:{
          title:'首页',
          belong:'home'
        }
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/productPage.vue'),
        meta:{
          title:'产品中心',
          belong:'product'
        }
      },
      {
        path: '/download',
        name: 'download',
        component: () => import('../views/downloadPage.vue'),
        meta:{
          title:'下载中心',
          belong:'download'
        }
      },
      {
        path: '/bluetooth',
        name: 'bluetooth',
        component: () => import('../views/bluetoothPage.vue'),
        meta:{
          title:'蓝牙助手',
          belong:'download'
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contactPage.vue'),
        meta:{
          title:'联系我们',
          belong:'contact'
        }
      }
    ]
  },
  {
    path: '/mb',
    redirect: '/mb/home',
    component: mbLayout,
    children:[
      {
        path: '/mb/home',
        name: 'mb-home',
        component: () => import('../views/mb/homePage.vue'),
        meta:{
          title:'首页',
          belong:'home',

        }
      },
      {
        path: '/mb/product',
        name: 'mb-product',
        component: () => import('../views/mb/productPage.vue'),
        meta:{
          title:'产品中心',
          belong:'product'
        }
      },
      {
        path: '/mb/download',
        name: 'mb-download',
        component: () => import('../views/mb/downloadPage.vue'),
        meta:{
          title:'下载中心',
          belong:'download'
        }
      },
      {
        path: '/mb/bluetooth',
        name: 'mb-bluetooth',
        component: () => import('../views/mb/bluetoothPage.vue'),
        meta:{
          title:'蓝牙助手',
          belong:'download'
        }
      },
      {
        path: '/mb/contact',
        name: 'mb-contact',
        component: () => import('../views/mb/contactPage.vue'),
        meta:{
          title:'联系我们',
          belong:'contact'
        }
      }
    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return {
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  let arrPath = to.path.split('/');
  let hasMb = to.path.split('/').includes('mb')
  if(window.screen.width < 768 && !hasMb){
    next({ path: '/mb'+to.path})
  }else if(window.screen.width >= 768 && hasMb){
    next({ path: '/'+ arrPath[2] })
  }else{
    next()
  }
})

export default router
