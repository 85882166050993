<template>
  <div class="page-footer">
    <div class="page-footer_container">
      <div class="page-footer_container__item" @click="goPage('/home')">
        <img class="footer_logo" src="../assets/images/logo.png" />
      </div>
      <div
        class="page-footer_container__item"
        v-for="(item, idx) in footLinks"
        :key="idx"
      >
        <p class="foot-item-title">{{ item.name }}</p>
        <p
          class="foot-item-link"
          v-for="(linkItem, i) in item.list"
          :key="i"
          @click="goPage(linkItem.linkUrl)"
        >
          {{ linkItem.linkName }}
        </p>
      </div>
      <div class="page-footer_container__item">
        <p class="foot-item-title">在线购买</p>
        <p class="foot-item-link" @click="goPage()">淘宝</p>
        <p class="foot-item-link" @click="goPage2()">阿里巴巴</p>
      </div>
    </div>
    <p class="foot-copy">
      © 2018 - 2021 深圳市轻松云物联网有限公司 粤ICP备18148834号
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footLinks: [
        {
          name: '产品中心',
          list: [
            { linkName: '蓝牙模块', linkUrl: '/product' },
            { linkName: '蓝牙模块', linkUrl: '/product' }
          ]
        },
        {
          name: '下载中心',
          list: [
            { linkName: '规格书', linkUrl: '/bluetooth' },
            { linkName: '选型手册', linkUrl: '/bluetooth' },
            { linkName: '安装包', linkUrl: '/bluetooth' },
            { linkName: '参考源码', linkUrl: '/bluetooth' }
          ]
        },
        {
          name: '联系我们',
          list: [
            { linkName: '销售服务', linkUrl: '/contact' },
            { linkName: '技术支持', linkUrl: '/contact' },
            { linkName: '公司地址', linkUrl: '/contact' },
          ]
        }
      ],
    }
  },
  methods: {
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      if (!path) {
        window.open("https://shop183246246.taobao.com", "_blank");
      } else {
        this.$router.push(path)
      }
    },
    goPage2(path) {
      if (this.$route.path == path) {
        return;
      }
      if (!path) {
        window.open("https://eciotshop.1688.com/", "_blank");
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss">
.page-footer {
  position: relative;
  height: 300px;
  width: 100vw;
  background: url(../assets/images/footer-bg.png) no-repeat center;
  background-size: cover;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 46px;
  z-index: 10;
  &_container {
    width: 1200px;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    margin: 0 auto;
    &__item {
      flex: 1;
      padding-left: 80px;
      position: relative;
      &:not(:first-child)::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 154px;
        background: rgba(255, 255, 255, 0.1);
        left: 0;
        top: 0;
      }
      .foot-item-title {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 27px;
      }
      .foot-item-link {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 20px;
        margin-top: 12px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .footer_logo {
      display: block;
      height: 47px;
    }
  }
}
.foot-copy {
  text-align: center;
  margin: 0 auto 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 14px;
}
</style>