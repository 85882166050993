<template>
  <div class="page-header">
    <div class="page-header_container">
      <div class="header_logo" @click="goPage('/home')">
        <p style="font-size: 16px">易卌物联</p>
      </div>
      <div class="header_menu">
        <div
          v-for="item in menus"
          :key="item.key"
          class="menu-item"
          :class="[chooseName == item.key ? 'active' : '']"
          @click="goPage(item.path)"
        >
          {{item.name}}
        </div>
        <div
          class="menu-item"
          :class="[chooseName == 'shop' ? 'active' : '']"
          @click="goPage()"
        >
          官方商城
        </div>
      </div>
    </div>
    <div class="fixed-info" :class="[isSmall ? 'small-fixed' : '']">
      <div class="fixed-info-content-1" v-if="startChange" @click="closeFixedInfo">
        <img src="../assets/images/contact-icon.png" alt="" srcset="" />
        <p>联系我们</p>
      </div>
      <div class="fixed-info-content" v-else>
        <p class="fixed-title">
          <span>添加销售咨询</span>
          <img
            src="../assets/images/small-icon.png"
            class="small-icon"
            @click="closeFixedInfo"
          />
        </p>
        <p class="fixed-tip">为你疑难解答</p>
        <img class="fixed-pic" src="../assets/images/erweima.png" alt="" />
        <p class="fixed-tip-1">——&emsp;扫描二维码添加微信&emsp;——</p>
        <div>
          <p class="fixed-contact">
            <span class="contact-desc">服务热线</span>
            <span>18926554801 (青先生)</span>
          </p>
          <p class="fixed-contact">
            <span class="contact-desc">微信</span>
            <span>18926554801</span>
          </p>
          <p class="fixed-contact">
            <span class="contact-desc">QQ</span>
            <span>2201920828</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-header",
  data() {
    return {
      menus:[
        { key:'home', name:'首页', path:'/home' },
        { key:'product', name:'产品中心', path:'/product' },
        { key:'download', name:'下载中心', path:'/download' },
        { key:'contact', name:'联系我们', path:'/contact' }
      ],
      chooseName: "",
      isSmall: true,
      startChange:true
    };
  },
  watch: {
    $route(to) {
      this.chooseName = to.meta.belong;
    },
  },
  mounted() {
    const { meta } = this.$route;
    this.chooseName = meta.belong;
    setTimeout(()=>{
      this.closeFixedInfo(1)
    },20000)
  },
  methods: {
    closeFixedInfo(curr) {
      if(curr == 1){
        if(!this.isSmall){
          return
        }
      }
      this.isSmall = !this.isSmall;
      if(!this.startChange){
        setTimeout(()=>{
          this.startChange = !this.startChange;
        },200)
      }else{
        this.startChange = !this.startChange;
      }
      
    },
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      if (!path) {
        window.open("https://shop183246246.taobao.com", "_blank");
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  position: fixed;
  top: 0;
  height: 75px;
  width: 100vw;
  background: url(../assets/images/header-bg.png) no-repeat center;
  background-color: #fff;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  &_container {
    width: 1200px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    .header_logo {
      display: block;
      height: 70px;
      width: 125px;
      background: url(../assets/images/logo.png) no-repeat center;
      background-size: contain;
      line-height: 58px;
      padding-right: 1px;
      text-align: right;
      color: transparent;
    }
    .header_menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      .menu-item {
        height: 20px;
        margin-left: 85px;
        position: relative;
        cursor: pointer;
        &::after {
          position: absolute;
          content: "";
          height: 2px;
          width: 0;
          background: #fff;
          bottom: -5px;
          left: 0;
          right: 0;
          margin: auto;
          transition: all 0.2s ease-in-out;
        }
        &.active {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            height: 2px;
            width: 100%;
            background: #fff;
            bottom: -5px;
            left: 0;
            right: 0;
            margin: auto;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}

.fixed-info {
  position: fixed;
  width: 331px;
  height: 464px;
  background: url(../assets/images/pc-fixed-btn.png) no-repeat center;
  background-size: contain;
  right: 10px;
  bottom:30px;
  color: #ffffff;
  z-index: 20;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  &.small-fixed {
    width: 100px;
    height: 100px;
    transition: all 0.4s ease-in-out;
    border: none;
    border-radius: 12px;
  }
  &-content-1 {
    width: 100px;
    height: 100px;
    background-color: #679aff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    > img {
      height: 40px;
      width: 40px;
    }
  }
  &-content {
    width: 331px;
    height: 464px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .fixed-title {
    position: relative;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    .small-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
      cursor: pointer;
    }
  }
  .fixed-tip {
    width: 108px;
    height: 23px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    background: #2095fe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fixed-tip-1 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }
  .fixed-pic {
    widows: 171px;
    height: 173px;
  }
  .fixed-contact {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
    .contact-desc {
      display: inline-block;
      width: 100px;
      text-align: right;
      padding-right: 17px;
      flex: auto;
    }
  }
}
</style>
