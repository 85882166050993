<template>
  <div class="page">
    <page-header />
    <div class="page-container">
      <router-view></router-view>
    </div>
    <page-footer />
  </div>
</template>

<script>
import pageHeader from "@/components/page-header.vue";
import pageFooter from "@/components/page-footer.vue";
export default {
  components: {
    pageHeader,
    pageFooter,
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
.page-container {
  min-height: calc(100vh - 375px);
  padding-top: 75px;
}
</style>
