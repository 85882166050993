<template>
  <div class="mb-page">
    <div class="mb-page_header">
      <img class="view" src="../assets/images/view-list.png" @click="open()" />
      <div class="logo" @click="goPage('/mb/home')">易卌物联</div>
    </div>
    <div class="mb-menu" :class="[isOpen ? 'open' : '']">
      <p class="mb-menu-head">
        <img
          class="close"
          src="../assets/images/mb-close.png"
          @click="close()"
        />
      </p>
      <p
        class="mb-menu-item"
        v-for="item in menus"
        :key="item.key"
        :class="[chooseRouter == item.key ? 'choose' : '']"
        @click="goPage(item.path)"
      >
        {{ item.name }}
      </p>
      <p class="mb-menu-item" @click="goPage()">官方商城</p>
    </div>
    <div class="mb-page_container">
      <router-view></router-view>
    </div>
    <div class="mb-page_foot">
      <img
        class="logo"
        src="../assets/images/logo.png"
        @click="goPage('/mb/home')"
      />
      <div class="foot-menu">
        <div class="foot-menu-item" v-for="(item, idx) in footLinks" :key="idx">
          <p class="menu-item-title">{{ item.name }}</p>
          <p
            class="menu-item-link"
            v-for="(linkItem, i) in item.list"
            :key="i"
            @click="goPage(linkItem.linkUrl)"
          >
            {{ linkItem.linkName }}
          </p>
        </div>
        <div class="foot-menu-item">
          <p class="menu-item-title">在线购买</p>
          <p class="menu-item-link" @click="goPage()">淘宝</p>
          <p class="menu-item-link" @click="goPage2()">阿里巴巴</p>
        </div>
      </div>
      <p class="copy">
        © 2018 - 2021 深圳市轻松云物联网有限公司 粤ICP备18148834号
      </p>
    </div>
    <div
      class="zixun-fixed-btn"
      style="position: relative; background-color: #fff"
    ></div>
    <div class="zixun-fixed-btn" @click="showHelp = true">
      <img class="zixun-icon" src="../assets/images/zixun-icon.png" alt="" />
      <p>点我咨询</p>
    </div>
    <div class="help-dialog" v-if="showHelp">
      <div class="help-info">
        <p class="fixed-title">
          添加销售咨询
          <img
            class="close-dialog"
            src="../assets/images/white-close.png"
            @click="closeHelp"
          />
        </p>
        <p class="fixed-tip">为你疑难解答</p>
        <img class="fixed-pic" src="../assets/images/erweima.png" alt="" />
        <p class="fixed-tip-1">——&emsp;扫描二维码添加微信&emsp;——</p>
        <div class="content-reg">
          <div class="fixed-contact">
            <p class="contact-title">服务热线</p>
            <p class="contact-desc">18926554801 (青先生)</p>
          </div>
          <div class="fixed-contact">
            <p class="contact-title">微信</p>
            <p class="contact-desc">18926554801</p>
          </div>
          <div class="fixed-contact">
            <p class="contact-title">QQ</p>
            <p class="contact-desc">2201920828</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      menus: [
        { key: 'home', name: '首页', path: '/mb/home' },
        { key: 'product', name: '产品中心', path: '/mb/product' },
        { key: 'download', name: '下载中心', path: '/mb/download' },
        { key: 'contact', name: '联系我们', path: '/mb/contact' }
      ],
      footLinks: [
        {
          name: '产品中心',
          list: [
            { linkName: '蓝牙模块', linkUrl: '/mb/product' },
            { linkName: '蓝牙模块', linkUrl: '/mb/product' }
          ]
        },
        {
          name: '下载中心',
          list: [
            { linkName: '规格书', linkUrl: '/mb/bluetooth' },
            { linkName: '选型手册', linkUrl: '/mb/bluetooth' },
            { linkName: '安装包', linkUrl: '/mb/bluetooth' },
            { linkName: '参考源码', linkUrl: '/mb/bluetooth' }
          ]
        },
        {
          name: '联系我们',
          list: [
            { linkName: '销售服务', linkUrl: '/mb/contact' },
            { linkName: '技术支持', linkUrl: '/mb/contact' },
            { linkName: '公司地址', linkUrl: '/mb/contact' },
          ]
        }
      ],
      isOpen: false,
      noScroll: false,
      chooseRouter: "",
      showHelp: false,
    };
  },
  watch: {
    $route(to) {
      this.chooseRouter = to.meta.belong;
    },
  },
  mounted() {
    const { meta } = this.$route;
    this.chooseRouter = meta.belong;
    setTimeout(() => {
      this.showHelp = true;
    }, 20000);
  },
  methods: {
    open() {
      this.isOpen = true;
      this.noScroll = true;
    },
    close() {
      this.isOpen = false;
      this.noScroll = false;
    },
    closeHelp() {
      this.showHelp = false;
    },
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      if (!path) {
        window.open("https://shop183246246.taobao.com", "_blank");
      } else {
        this.$router.push(path);
      }
      this.isOpen = false;
    },
    goPage2(path) {
      if (this.$route.path == path) {
        return;
      }
      if (!path) {
        window.open("https://eciotshop.1688.com/", "_blank");
      } else {
        this.$router.push(path);
      }
      this.isOpen = false;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.help-dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 150;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-info {
  width: 350px;
  height: calc(350px * 1.415);
  background: url(../assets/images/fixed-bg.png) no-repeat center;
  background-size: cover;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
  z-index: 100;

  .fixed-title {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    .close-dialog {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
    }
  }
  .fixed-tip {
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    background: #2095fe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fixed-tip-1 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
  .fixed-pic {
    widows: 171px;
    height: 173px;
  }
  .content-reg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fixed-contact {
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .contact-title {
      width: 40%;
      text-align: right;
      padding-right: 17px;
    }
    .contact-desc {
      width: 60%;
    }
  }
}

.mb-page {
  width: 100vw;
  padding-top: 65px;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
}
.mb-page_header {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 65px;
  background: url(../assets/images/header-bg.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 80;
  .logo {
    display: block;
    background: url(../assets/images/logo.png) no-repeat center;
    background-size: contain;
    color: transparent;
    height: 45px;
    width: 120px;
    font-size: 16px;
    line-height: 34px;
    text-align: right;
  }
  .view {
    position: absolute;
    height: 32px;
    width: 32px;
    top: 0;
    bottom: 0;
    left: 18px;
    margin: auto;
  }
}
.mb-menu {
  position: fixed;
  height: 0;
  width: 100vw;
  top: 0;
  left: 0;
  background: #fff;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  z-index: 200;
  &.open {
    height: 100vh;
  }
  &-head {
    height: 65px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.3);
    position: relative;
    .close {
      position: absolute;
      height: 32px;
      width: 32px;
      top: 0;
      bottom: 0;
      left: 18px;
      margin: auto;
    }
  }
  &-item {
    height: 60px;
    padding-left: 30px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.3);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    &.choose {
      background: rgba(221, 221, 221, 0.3);
    }
  }
}
.mb-page_container {
  min-height: calc(100vh - 365px);
  &.noScroll {
    height: calc(100vh - 365px);
    overflow: hidden;
  }
}
.mb-page_foot {
  width: 100vw;
  height: 300px;
  background: url(../assets/images/mb-foot-bg.png) no-repeat center;
  background-size: 100% 100%;
  padding: 20px 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .foot-menu {
    flex: auto;
    display: flex;
    width: 100%;
    padding: 25px 20px 0;
    flex-direction: row;
    justify-content: space-evenly;
    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
    }
    .menu-item-title {
      font-weight: bold;
      font-size: 16px;
    }
    .menu-item-link {
      font-size: 12px;
      font-weight: 400;
      margin-top: 12px;
      color: rgba(255, 255, 255, 0.8);
      &:active {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .logo {
    display: block;
    height: 52px;
    margin: 0 20px;
  }
  .copy {
    font-size: 10px;
    margin: 0 20px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }
}
.zixun-fixed-btn {
  position: fixed;
  font-size: 18px;
  font-weight: 500;
  width: 100vw;
  height: 60px;
  bottom: 0;
  background: #f44d23;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .zixun-icon {
    height: 24px;
    margin: 0 10px;
  }
}
</style>
  